@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
#root {
  height: 100%;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 14px;
}

*,
*:before,
*:after,
*:placeholder-shown {
  font-family: inherit;
}

